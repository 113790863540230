<template>
    <div style="padding-left: 10px">
      <div id="data-list-list-view" class="data-list-container">
        <div class="lg:hidden mt-3">
          <div class="mb-2 flex flex-no-wrap">
            <div class="w-full pr-1">
              <vs-input icon="search" style="width: 100%" placeholder="Search" v-model="searchInputString" />
            </div>
            <!-- <div class="pl-1" style="width: 14.5rem">
            <vs-select
              v-model="dataTableParams.filter"
              placeholder="All Nurses"
              autocomplete
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.label"
                v-for="(item, index) in filterList"
              />
            </vs-select>
          </div> -->
            <div class="w-24 pl-1">
              <vs-select width="100%" placeholder="10" autocomplete v-model="dataTableParams.limit">
                <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in limitOptions" />
              </vs-select>
            </div>
          </div>
          <ul>
            <li :key="indextr" @click="redirectToClinicEdit(users[indextr]._id)" v-for="(tr, indextr) in users">
              <div class="rounded mb-2 p-4" style="border: 1px solid #e8e8e8">
                <div class="pb-2">
                  <h4 class="">
                    {{
                        users[indextr].clinicName
                          ? users[indextr].clinicName
                          : "N/A" | capitalize
                    }}
                  </h4>
                </div>
                <div>
                  <p class="ellipsis">
                    {{ users[indextr].postalAddress || "N/A" }}
                  </p>
                </div>
                <!-- <div class="flex">
                  <vs-button
                    size="small"
                    style="float: left"
                    type="border"
                    @click="redirectToClinicEdit(user[indextr]._id)"
                    class="m-1"
                    >Edit</vs-button
                  >
                </div> -->
              </div>
            </li>
          </ul>
        </div>
        <vs-table class="hidden lg:block" ref="table" :sst="true" :total="totalDocs" :data="users" @search="search"
          @change-page="handleChangePage" @sort="handleSort" :max-items="dataTableParams.limit" search
          :noDataText="noDataText">
          <!-- <div
          slot="header"
          class="flex flex-wrap-reverse flex-grow justify-between"
        >

          <div>
            <vs-select
              v-model="dataTableParams.filter"
              placeholder="All Nurses"
              autocomplete
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.label"
                v-for="(item, index) in filterList"
              />
            </vs-select>
          </div>
        </div> -->

          <template slot="thead">
            <vs-th sort-key="clinicName">Clinic Name</vs-th>
            <vs-th sort-key="address">Address</vs-th>
            <vs-th v-if="canEditClinic()">Actions</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].clinicName">{{
                  data[indextr].clinicName | capitalize
              }}</vs-td>
              <vs-td :data="getDisplayAddress(data, indextr)">{{
                  getDisplayAddress(data, indextr) | capitalize
              }}</vs-td>
              <!-- <vs-td :data="data[indextr].role">{{ data[indextr].role }}</vs-td> -->
              <vs-td :data="data[indextr]._id" v-if="canEditClinic()">
                <!-- v-if="check_has_permission('updateNurseManagement')" -->
                <!-- <vx-tooltip
                  text="Edit Clinic Details"
                > -->
                <vs-button style="float: left" size="small" type="border"
                  @click="redirectToClinicEdit(data[indextr]._id)" class="m-1">
                  Edit</vs-button>
                <!-- </vx-tooltip> -->
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div class="
            m-2
            flex
            sm:justify-between
            justify-center
            items-center
            flex-wrap
          " 
            style="height: 35px;padding-bottom:30px">
          <span class="mr-2">
            {{
                dataTableParams.page * dataTableParams.limit -
                (dataTableParams.limit - 1)
            }}
            -
            {{
                totalDocs - dataTableParams.page * dataTableParams.limit > 0
                  ? dataTableParams.page * dataTableParams.limit
                  : totalDocs
            }}
            of {{ totalDocs }}
          </span>
          <div class="pagination-div" v-if="serverResponded">
            <paginate :page-count="totalPage" :click-handler="handleChangePage" class="pagination" :page-range="5"
              :prevText="'<'" :nextText="'>'"></paginate>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "organization-table",
  inject: ['getRoleRouteName'],
  components: {},
  props: {
    canDelete: {
      type: Boolean,
      default: false,
    },
    viewRoute: {
      type: String,
      default: "FranchiseNurseDetails",
    },
    viewDetailRoute: {
      type: String,
      default: "SuperAdminNurseDetailView",
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    createRoute: {
      type: String,
      default: "FranchiseNurseCreate",
    },
    canAssignClinic: {
      type: Boolean,
      default: true,
    },
    franchiseId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      noDataText: "Loading..",
      orgCount: 0,
      totalDocs: 0,
      currentPage: 1,
      searchInputString: null,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25,
        id: null,
      },
      users: [],
      serverResponded: false,
      limitOptions: [5, 10, 25, 50, 100],
      filterList: [
        { value: "All", label: "All Nurses" },
        { value: "Approved", label: "Approved" },
        { value: "Unapproved", label: "Unapproved" },
      ],
      awaitingSearch: null,
      userPermissions_on: [],
    };
  },
  methods: {
    ...mapActions("organization", ["fetchOrganizationClinicsList"]),
    ...mapActions("admin", ["fetchUserPermission"]),
    search(searching) {
      this.searchInputString = searching;
    },
    getDisplayAddress(data, indextr) {
      if (data[indextr].address && data[indextr].address.displayAddress) {
        return data[indextr].address.displayAddress
      }
      return "";
    },
    moment(date) {
      return moment(date);
    },
    async getOrganizationClinicsList() {
      this.$vs.loading();
      if (this.dataTableParams.status == "all")
        this.dataTableParams.status = "";
      try {
        const res = await this.fetchOrganizationClinicsList(
          this.dataTableParams
        );
        this.$vs.loading.close();
        this.users = res.data.data.docs;
        this.totalDocs = res.data.data.pagination.total;
        this.page = res.data.data.pagination.page;
        this.serverResponded = true;
        this.noDataText = "No clinics available";
      } catch (err) {
        console.log("This is the error ::", err);
      }
    },
    // getOrganizationClinics(id) {
    //   this.$vs.loading();
    //   this.fetchOrganizationClinics({ id: id }).then((res) => {
    //     this.$vs.loading.close();
    //     console.log(res);
    //     this.noDataText = "No Clinics Available";
    //   });
    // },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getOrganizationClinicsList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      //   this.getOrganizationClinicsList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      //   this.getOrganizationClinicsList();
    },
    redirectToCreateClinic() {
      if (this.$isAuRegion()) {
        window.open(process.env.VUE_APP_CREATE_CLINIC_HUBSPOT_FORM_AU);
      } else {
        window.open(process.env.VUE_APP_CREATE_CLINIC_HUBSPOT_FORM_US)
      }
    },
    redirectToClinicEdit(id) {
      this.$router.push({name: this.getRoleRouteName('EditClinic'), params:{clinicId:id}});
    },
    getUserPermission() {
      const orgId = this.dataTableParams.id;
      const userId = this.$store.state.AppActiveUser._id

      this.fetchUserPermission({
        userId: userId,
        organizationId: orgId,
      })
        .then((res) => {
          const val = res.data;

          if (val.length > 0) {
            val.map((perm) => {
              this.userPermissions_on.push(perm.permission.name);
            });
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    canEditClinic() {
      return (this.userPermissions_on && this.userPermissions_on.some(name => name === 'Clinic'));
    }
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    nurseDetails: function (newVal, oldVal) {
      this.handleSearch(newVal);
    },
    searchInputString: function (newVal, oldVal) {
      this.handleSearch(newVal);
    },
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getOrganizationClinicsList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getOrganizationClinicsList();
      }
    },
    // "dataTableParams.filter": function (newfilterVal, oldFilterVal) {
    //   if (newfilterVal !== oldFilterVal) {
    //     this.serverResponded = false;
    //     this.dataTableParams.page = 1;
    //     this.dataTableParams.filter = newfilterVal;
    //     // this.getOrganizationClinicsList();
    //   }
    // },
    // "$store.state.AppActiveClinicId": function (newVal, oldVal) {
    //   if (newVal !== oldVal) {
    //     this.dataTableParams.activeClinicId = newVal;
    //     // this.getOrganizationClinicsList();
    //   }
    // },
  },
  async created() {
    this.dataTableParams.id = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
    );
    // this.dataTableParams.id = this.$route.params.organizationId;
    this.getOrganizationClinicsList();
    this.getUserPermission();
  },
};
</script>
<style scoped>
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
